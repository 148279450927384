import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    open: false,
    toggleMenu: () =>
      this.setState(
        prevState => ({ open: !prevState.open }),
        this.toggleNoScroll()
      ),
    hideMenu: () =>
      this.setState(
        { open: false },
        document.body.classList.remove('no-scroll')
      ),
  };

  toggleNoScroll() {
    if (this.state.open == false) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
