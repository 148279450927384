import React from 'react';
import AppProvider from 'store/provider';
import './src/styles/fonts/inter/inter.css';
import './src/styles/global.css';
import './src/styles/custom.css';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};
